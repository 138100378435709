import React, { Fragment } from "react";
import styled from "@emotion/styled";
import {
  Form,
  Input,
  Typography,
  Radio,
  Button,
  Icon,
  InputNumber
} from "antd";
import {
  PRICE_FIELD,
  PRICE_LABEL,
  TAX_OPTION,
  TYPE_OPTION,
  RENT_OPTION
} from "../const";

const { Title } = Typography;

const ActionFormContainer = styled(Form)`
  width: 100%;
`;

const PriceContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
  .ant-form-item-label {
    font-weight: 600;
    label {
      font-size: 1rem;
    }
  }
`;

const PriceOption = styled(Radio.Group)`
  .ant-radio-wrapper {
    display: block;
    height: 40px;
    line-height: 40px;
    .ant-radio {
      transform: scale(1.2);
      margin-right: 0.5rem;
    }
    span {
      font-size: 1rem;
      font-weight: bolder;
    }
  }
`;

const StatusContainer = styled.div`
  margin-top: 2rem;
  display: grid;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  grid-template-columns: 1fr 1fr 1fr;
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const VerticalRadio = styled(Radio)`
  display: block;
  height: 40px;
  line-height: 40px;
  .ant-radio {
    transform: scale(1.2);
    margin-right: 0.5rem;
  }
  span {
    font-size: 1rem;
    font-weight: bolder;
  }
`;

const StatusInnerContainer = styled.div`
  margin-bottom: 1rem;
`;

const SubmitContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 2rem;
  justify-content: flex-end;
`;

const SubmitButton = styled(Button)`
  font-weight: bolder;
`;

const StyledInputNumber = styled(InputNumber)`
  width: 100%;
`;

const PriceItem = ({ form, field, defaultPrice, disabled, ...props }) => {
  const { getFieldDecorator, setFieldsValue, getFieldValue } = form;
  const selectedPriceOption = getFieldValue("selectedPriceOption");
  return (
    <Fragment>
      {getFieldDecorator("selectedPriceOption")(
        <PriceOption
          disabled={disabled}
          options={[{ value: field, label: PRICE_LABEL[field] }]}
          onChange={e => {
            setFieldsValue({
              ...Object.values(PRICE_FIELD).reduce(
                (prev, curr) => ({ ...prev, [curr]: "" }),
                {}
              ),
              [e.target.value]:
                e.target.value === PRICE_FIELD.OWNER_NET ? "" : defaultPrice
            });
          }}
          name="price"
        />
      )}
      <Form.Item>
        {getFieldDecorator(field, {
          rules: [
            {
              pattern: /^\d*$/,
              message: "The input is not valid number!"
            }
          ]
        })(
          <StyledInputNumber
            formatter={value =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={value => value.replace(/\$\s?|(,*)/g, "")}
            autoComplete="off"
            disabled={selectedPriceOption !== field || disabled}
            onChange={e => {
              setFieldsValue({
                ...Object.values(PRICE_FIELD).reduce(
                  (prev, curr) => ({ ...prev, [curr]: "" }),
                  {}
                ),
                [field]: e.toString()
              });
            }}
          />
        )}
      </Form.Item>
    </Fragment>
  );
};

const Price = props => {
  return (
    <PriceContainer>
      {Object.values(PRICE_FIELD).map(field => (
        <PriceItem key={field} field={field} {...props} />
      ))}
    </PriceContainer>
  );
};

const Tax = ({ form, disabled }) => {
  const { getFieldDecorator } = form;
  return (
    <StatusInnerContainer>
      <Title level={3}>ภาษีธุรกิจเฉพาะ</Title>
      <Form.Item>
        {getFieldDecorator("tax", {
          rules: [
            {
              required: true,
              message: "กรุณาเลือกรูปแบบของภาษี"
            }
          ]
        })(
          <Radio.Group>
            {Object.keys(TAX_OPTION).map(key => (
              <VerticalRadio value={key} key={key} disabled={disabled}>
                {TAX_OPTION[key]}
              </VerticalRadio>
            ))}
          </Radio.Group>
        )}
      </Form.Item>
    </StatusInnerContainer>
  );
};

const Type = ({ form, disabled }) => {
  const { getFieldDecorator } = form;
  return (
    <StatusInnerContainer>
      <Title level={3}>Sale Type</Title>
      <Form.Item>
        {getFieldDecorator("type", {
          rules: [
            {
              required: true,
              message: "กรุณาเลือก Sale type"
            }
          ]
        })(
          <Radio.Group>
            {Object.keys(TYPE_OPTION).map(key => (
              <VerticalRadio value={key} key={key} disabled={disabled}>
                {TYPE_OPTION[key]}
              </VerticalRadio>
            ))}
          </Radio.Group>
        )}
      </Form.Item>
    </StatusInnerContainer>
  );
};

const Rent = ({ form, disabled }) => {
  const { getFieldDecorator } = form;
  return (
    <StatusInnerContainer>
      <Title level={3}>สถานะการเช่า</Title>
      <Form.Item>
        {getFieldDecorator("rent", {
          rules: [
            {
              required: true,
              message: "กรุณาเลือกสถานะการจอง"
            }
          ]
        })(
          <Radio.Group>
            {Object.keys(RENT_OPTION).map(key => (
              <VerticalRadio value={key} key={key} disabled={disabled}>
                {RENT_OPTION[key]}
              </VerticalRadio>
            ))}
          </Radio.Group>
        )}
      </Form.Item>
    </StatusInnerContainer>
  );
};

const Status = ({ ...props }) => {
  return (
    <StatusContainer>
      <Type {...props} />
      <Tax {...props} />
      <Rent {...props} />
    </StatusContainer>
  );
};

const Submit = ({ disabled, submitText }) => {
  return (
    <SubmitContainer>
      <SubmitButton
        size="large"
        shape="round"
        htmlType="submit"
        type="primary"
        disabled={disabled}
      >
        {submitText} <Icon type="check" />
      </SubmitButton>
    </SubmitContainer>
  );
};

const ActionForm = ({ onSubmit, ...props }) => {
  return (
    <ActionFormContainer onSubmit={onSubmit}>
      <Price {...props} />
      <Status {...props} />
      <Submit {...props} />
    </ActionFormContainer>
  );
};

export default ActionForm;
