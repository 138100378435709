import { useCallback, useEffect, useRef, useMemo, useState } from "react";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { useSelectedProjectState, useProjectSummary } from "./project";
import {
  saveLabelUnitAction,
  selectImageAction,
  setLabelLoadingAction,
  setImagesAction
} from "../redux/reducer";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { API_ENDPOINT } from "../const";

const GET_CUSTOM_UNLABELED_UNIT = gql`
  query custom_unlabeled_unit($stockId: String!) {
    custom_unlabeled_unit(stock_id: $stockId) {
      id
      images {
        url
      }
      note
      plusPrice
      stockId
    }
  }
`;

const GET_UNLABELED_UNIT = gql`
  query unlabeled_unit($project_name: String!) {
    unlabeled_unit(project_name: $project_name) {
      id
      images {
        url
      }
      note
      plusPrice
      stockId
    }
  }
`;

const LABEL_UNIT = gql`
  mutation label_unit(
    $id: String!
    $price: Float
    $priceType: String
    $transfer_fee_option: String
    $has_transfer_tax: String
    $sale_type: String
    $skip_label_price: Boolean
    $attachments: [String]
    $hide_attachments: [String]
    $rent_status: String
  ) {
    label_unit(
      data: {
        id: $id
        price: $price
        priceType: $priceType
        transfer_fee_option: $transfer_fee_option
        has_transfer_tax: $has_transfer_tax
        sale_type: $sale_type
        skip_label_price: $skip_label_price
        attachments: $attachments
        rent_status: $rent_status
        hide_attachments: $hide_attachments
      }
    ) {
      id
    }
  }
`;

const UPDATE_UNIT_MUTATION = gql`
  mutation update_unit(
    $id: String!
    $price: Float
    $priceType: String
    $transfer_fee_option: String
    $has_transfer_tax: String
    $sale_type: String
    $skip_label_price: Boolean
    $attachments: [String]
    $hide_attachments: [String]
    $rent_status: String
  ) {
    update_unit(
      data: {
        id: $id
        price: $price
        priceType: $priceType
        transfer_fee_option: $transfer_fee_option
        has_transfer_tax: $has_transfer_tax
        sale_type: $sale_type
        skip_label_price: $skip_label_price
        attachments: $attachments
        rent_status: $rent_status
        hide_attachments: $hide_attachments
      }
    ) {
      id
    }
  }
`;

const UPLOAD = gql`
  mutation upload($base64str: String!) {
    upload_image(base64str: $base64str) {
      url
    }
  }
`;

const GET_NEED_UPDATE_UNITS = gql`
  query need_update_units {
    need_update_units {
      id
      stockId
      note
      noteWhenLabel
      plusPrice
      plusPriceWhenLabel
      images {
        url
      }
      shortProject {
        id
        name
      }
    }
  }
`;

export const useSelectedImageState = () =>
  useSelector(state => _.get(state, ["selectedImage"]));
export const useEditImageModalState = () =>
  useSelector(state => _.get(state, ["editImageModal"]));
export const useLabelLoadingState = () =>
  useSelector(state => _.get(state, "labelLoading"));
const useBucketImagesState = () =>
  useSelector(state => _.get(state, ["bucketImages"]), _.isEqual);
const useShowImagesState = () =>
  useSelector(state => _.get(state, ["showImages"]), _.isEqual);
const useNotShowImagesState = () =>
  useSelector(state => _.get(state, ["notShowImages"]), _.isEqual);
const useSavedLabelUnitState = () =>
  useSelector(state => _.get(state, ["savedLabelUnit"]));
export const useUnlabeledUnit = () => {
  const selectedProject = useSelectedProjectState();
  const { loading, data, refetch, error } = useQuery(GET_UNLABELED_UNIT, {
    variables: { project_name: selectedProject }
  });
  return {
    unlabeled_unit: data && data.unlabeled_unit,
    loading,
    refetch,
    error
  };
};

export const useImages = () => {
  const { unlabeled_unit, ...rest } = useUnlabeledUnit();
  const images = useMemo(
    () =>
      (_.get(unlabeled_unit, "images") || []).map(
        image =>
          `${API_ENDPOINT}/proxy?url=${window.encodeURIComponent(image.url)}`
      ),
    [unlabeled_unit]
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setImagesAction(images, [], []));
  }, [images, dispatch]);
  const bucketImages = useBucketImagesState();
  const showImages = useShowImagesState();
  const notShowImages = useNotShowImagesState();
  const setImages = useCallback(
    (...params) => dispatch(setImagesAction(...params)),
    [dispatch]
  );
  return {
    images,
    bucketImages,
    showImages,
    notShowImages,
    setImages,
    ...rest
  };
};

export const useCustomUnitImages = stockId => {
  const { data, ...rest } = useCustomUnlabeledUnit(stockId);
  const unit = _.get(data, "custom_unlabeled_unit");
  const images = useMemo(
    () =>
      (_.get(unit, "images") || []).map(
        image =>
          `${API_ENDPOINT}/proxy?url=${window.encodeURIComponent(image.url)}`
      ),
    [unit]
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setImagesAction(images, [], []));
  }, [images, dispatch]);
  const bucketImages = useBucketImagesState();
  const showImages = useShowImagesState();
  const notShowImages = useNotShowImagesState();
  const setImages = useCallback(
    (...params) => dispatch(setImagesAction(...params)),
    [dispatch]
  );
  return {
    images,
    bucketImages,
    showImages,
    notShowImages,
    setImages,
    ...rest
  };
};

export const useSelectImage = () => {
  const dispatch = useDispatch();
  const selectImage = useCallback(
    (image, open) => dispatch(selectImageAction(image, open)),
    [dispatch]
  );
  return { selectImage };
};

const useSavedLabelUnit = () => {
  const dispatch = useDispatch();
  const savedLabelUnit = useSavedLabelUnitState();
  const saveLabelUnit = useCallback(
    data => dispatch(saveLabelUnitAction(data)),
    [dispatch]
  );
  return { savedLabelUnit, saveLabelUnit };
};

export const useLabelUnit = () => {
  const { refetch } = useProjectSummary();
  const { refetch: refetchUnlabeled } = useUnlabeledUnit();
  const { savedLabelUnit, saveLabelUnit } = useSavedLabelUnit();
  const loading = useLabelLoadingState();
  const dispatch = useDispatch();
  const [labelUnitMutation] = useMutation(LABEL_UNIT);
  const labelUnit = useCallback(
    async (id, data) => {
      dispatch(setLabelLoadingAction(true));
      await labelUnitMutation({ variables: { id, ...data } });
      await refetch();
      await refetchUnlabeled();
      dispatch(setLabelLoadingAction(false));
    },
    [dispatch]
  );
  return {
    labelUnit,
    loading,
    savedLabelUnit,
    saveLabelUnit
  };
};

export const useConfirmLabel = () => {
  const [loading, setLoading] = useState(false);
  const [uploadedImg, setUploadedImg] = useState(0);
  const { showImages, notShowImages } = useImages();
  const { savedLabelUnit, labelUnit } = useLabelUnit();
  const { refetch } = useUnlabeledUnit();
  const [uploadMutation] = useMutation(UPLOAD);
  const confirm = async () => {
    setLoading(true);
    const showImageUrls = [];
    const notShowImageUrls = [];
    for (const image of showImages) {
      if (image.includes("https://")) {
        showImageUrls.push(image);
      } else {
        const {
          data: {
            upload_image: { url }
          }
        } = await uploadMutation({ variables: { base64str: image } });
        showImageUrls.push(url);
      }
      setUploadedImg(showImageUrls.length + notShowImageUrls.length);
    }
    for (const image of notShowImages) {
      if (image.includes("https://")) {
        showImageUrls.push(image);
      } else {
        const {
          data: {
            upload_image: { url }
          }
        } = await uploadMutation({ variables: { base64str: image } });
        notShowImageUrls.push(url);
      }
      setUploadedImg(showImageUrls.length + notShowImageUrls.length);
    }
    const { id, ...rest } = savedLabelUnit;
    await labelUnit(id, {
      ...rest,
      attachments: showImageUrls,
      hide_attachments: notShowImageUrls
    });
    setLoading(false);
  };
  return { confirm, loading, uploadedImg };
};

export const useNeedUpdateUnits = () => {
  return useQuery(GET_NEED_UPDATE_UNITS);
};

export const useUpdateUnit = () => {
  const [updateUnitMutation] = useMutation(UPDATE_UNIT_MUTATION);
  const [loading, setLoading] = useState(false);
  const updateUnit = async (id, data) => {
    setLoading(true);
    try {
      await updateUnitMutation({ variables: { id, ...data } });
    } catch (err) {
      throw err;
    } finally {
      setLoading(false);
    }
  };
  return { updateUnit, loading };
};

export const useCustomUnlabeledUnit = stockId => {
  return useQuery(GET_CUSTOM_UNLABELED_UNIT, { variables: { stockId } });
};
