import React, { useState } from "react";
import { Layout, Menu, Icon } from "antd";
import styled from "@emotion/styled";
import { Link, Route } from "react-router-dom";
import { useLogout } from "../hooks/user";

const { Sider } = Layout;

const Logo = styled.img`
  width: 100%;
  padding: 1rem;
`;

const CollapseSider = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const onCollapse = e => setCollapsed(e);
  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={onCollapse}
      breakpoint="xs"
      collapsedWidth="0"
    >
      {children}
    </Sider>
  );
};

const SidebarInner = ({
  match: {
    params: { feature, page }
  }
}) => {
  const logout = useLogout();
  return (
    <CollapseSider>
      <Logo src="/logo/logo_propfit.svg" />
      <Menu theme="dark" selectedKeys={[`/${feature}/${page}`]} mode="inline">
        <Menu.Item key="/manage/label">
          <Link to="/manage/label">
            <Icon type="file-done" />
            <span>Label Data</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="/manage/update">
          <Link to="/manage/update">
            <Icon type="file-sync" />
            <span>Update Data</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="/summary/project">
          <Link to="/summary/project">
            <Icon type="line-chart" />
            <span>Project Summary</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="/search">
          <Link to="/search">
            <Icon type="search" />
            <span>Search</span>
          </Link>
        </Menu.Item>
        {/* <Menu.Item key="image">
          <Link to="/manage/image">
            <Icon type="picture" />
            <span>Image Data</span>
          </Link>
        </Menu.Item> */}
        <Menu.Item key="logout" onClick={logout}>
          <Icon type="logout" />
          <span>Logout</span>
        </Menu.Item>
      </Menu>
    </CollapseSider>
  );
};

const Sidebar = () => {
  return <Route path="/:feature/:page?" component={SidebarInner} />;
};

export default Sidebar;
