import React, { useEffect } from "react";
import { Layout } from "antd";
import styled from "@emotion/styled";
import DataInfo from "./DataInfo";
import DataAction from "./DataAction";
import ProjectInfoMobile from "./ProjectInfoMobile";
import { useLabelUnit } from "../hooks/unit";
import _ from "lodash";

const { Content } = Layout;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  max-width: 1200px;
  margin: auto;
  grid-gap: 1rem;
`;

const LabelDataContainer = styled(Content)`
  padding: 32px 16px;
  overflow: auto;
`;

const LabelData = () => {
  const { savedLabelUnit, saveLabelUnit } = useLabelUnit();
  useEffect(() => {
    if (_.get(savedLabelUnit, "id")) {
      saveLabelUnit({});
    }
  }, []);
  return (
    <LabelDataContainer>
      <Container>
        <ProjectInfoMobile />
        <DataInfo />
        <DataAction />
      </Container>
    </LabelDataContainer>
  );
};

export default LabelData;
