import React, { useState, useCallback } from "react";
import styled from "@emotion/styled";
import { Select, Button, Collapse, Input, message, Icon } from "antd";
import { GET_UNIT_LIST_BY_ID, UPDATE_OPERATION_NOTE } from "./query";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { UnitDetailCard } from "components/UnitDetailCard";

const { Panel } = Collapse;
const { TextArea } = Input;

const Container = styled.div`
  padding: 32px;
  height: 100vh;

  @media screen and (max-width: 768px) {
    padding: 32px 8px;
  }
`;

const mapper = [
  "stockId",
  "refId",
  "projectName",
  "unitAddress",
  "unitProject",
  "floor",
  "numberOfBathroom",
  "numberOfBedroom",
  "note",
  "furnitureStatus",
  "viewDirection",
  "contactPersonDetail",
  "saleName",
  "saleTelephone",
  "ownerName",
  "ownerTel"
];

export const SearchPage = () => {
  const [selectedUnitList, setSelectedUnitList] = useState([]);
  const [operationNote, setOperationNote] = useState("");
  const [editingStockId, setEditingStockId] = useState(null);

  const [updateOperationNote] = useMutation(UPDATE_OPERATION_NOTE);
  const { data = {}, loading } = useQuery(GET_UNIT_LIST_BY_ID, {
    variables: { selectedUnitList }
  });

  const { unitList } = data;
  console.log(unitList);
  const handleChange = useCallback(value => {
    setSelectedUnitList(value);
  }, []);

  const handleUpdateUnit = useCallback(async () => {
    await updateOperationNote({
      variables: { id: editingStockId, operationNote }
    });
    message.success("Updated");
  }, [editingStockId, operationNote]);

  return (
    <Container>
      <div
        style={{ display: "flex", justifyContent: "center", marginBottom: 32 }}
      >
        <Select
          style={{ width: 200, marginRight: 16 }}
          mode="tags"
          placeholder="Unit Id"
          onChange={handleChange}
        />
      </div>
      {loading ? (
        <div style={{ textAlign: "center", fontSize: 30 }}>
          <Icon type="loading" />
        </div>
      ) : (
        <>
          {unitList &&
            (unitList.length === 0 ? (
              selectedUnitList.length === 0 ? (
                <h1>No Unit List</h1>
              ) : (
                <h1>Unit is not exist</h1>
              )
            ) : (
              <Collapse bordered={false}>
                {unitList.map(unit => (
                  <Panel header={unit.stockId} key={unit.stockId}>
                    <UnitDetailCard
                      unit={unit}
                      mapper={mapper}
                      style={{ marginBottom: 16 }}
                      key={unit.stockId}
                    />
                    <div style={{ display: "flex", marginBottom: 16 }}>
                      <div style={{ width: 150 }}>Operation Note</div>
                      <TextArea
                        rows={3}
                        defaultValue={unit.operationNote}
                        onChange={({ target: { value } }) => {
                          setOperationNote(value);
                          setEditingStockId(unit.id);
                        }}
                      />
                    </div>
                    <Button type="primary" onClick={handleUpdateUnit}>
                      Update
                    </Button>
                  </Panel>
                ))}
              </Collapse>
            ))}
        </>
      )}
    </Container>
  );
};
