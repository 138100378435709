import gql from "graphql-tag";

export const GET_UNIT_LIST_BY_ID = gql`
  query getUnitListById($selectedUnitList: [String]) {
    unitList(selectedUnitList: $selectedUnitList) {
      id
      stockId
      refId
      unitAddress
      floor
      unitProject
      numberOfBedroom
      numberOfBathroom
      note
      furnitureStatus
      viewDirection
      contactPersonDetail
      saleName
      saleTelephone
      ownerName
      ownerTel
      projectName
      operationNote
    }
  }
`;

export const UPDATE_OPERATION_NOTE = gql`
  mutation updateOperationNote($id: String!, $operationNote: String!) {
    updateOperationNote(id: $id, operationNote: $operationNote) {
      id
    }
  }
`;
