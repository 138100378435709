import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { useState, useEffect } from "react";
import { loginAction, logoutAction } from "../redux/reducer";
import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

const LOGIN = gql`
  mutation($email: String!, $password: String!) {
    login(data: { email: $email, password: $password }) {
      token
      user {
        id
        name
        email
        userId
        mobile
      }
    }
  }
`;

const GET_ME = gql`
  query me {
    me {
      isAuthenticated
      user {
        id
        name
        email
        userId
        mobile
      }
    }
  }
`;

export const useUser = () => {
  const user = useSelector(state => _.get(state, ["user"]));
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { loading: meLoading, data } = useQuery(GET_ME, {
    fetchPolicy: "network-only"
  });
  useEffect(() => {
    const user = _.get(data, "me.user");
    if (user) {
      dispatch(loginAction(data.me.user));
    }
    setLoading(meLoading);
  }, [data, meLoading, setLoading, dispatch]);
  return { user, loading };
};

export const useLogin = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [loginMutation] = useMutation(LOGIN);
  const login = async (email, password) => {
    setLoading(true);
    try {
      const {
        data: {
          login: { token, user }
        }
      } = await loginMutation({
        variables: { email, password }
      });
      localStorage.setItem("token", token);
      dispatch(
        loginAction(
          user ||
            // HACK, WAIT FOR BACKEND TO RETURN USER
            {}
        )
      );
    } catch (error) {
      if (error.graphQLErrors) {
        throw new Error(error.graphQLErrors[0].message);
      }
      throw error;
    } finally {
      setLoading(false);
    }
  };
  return { loading, login };
};

export const useLogout = () => {
  const dispatch = useDispatch();
  const logout = () => {
    localStorage.clear();
    dispatch(logoutAction());
  };
  return logout;
};
