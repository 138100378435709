import React, { useState } from "react";
import styled from "@emotion/styled";
import { useHistory, useParams } from "react-router";
import { Switch, Route } from "react-router-dom";
import { Layout, AutoComplete, Input, Icon, Typography } from "antd";
import {
  useSelectedProjectState,
  useSearchProject,
  useSelectProject
} from "../hooks/project";
import { MobileHidden } from "./Hidden";
import ProjectSummaryTable from "./ProjectSummaryTable";

const { Text } = Typography;
const { Header } = Layout;

const ManageHeaderContainer = styled(Header)`
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
`;
const StyledAutoComplete = styled(AutoComplete)`
  flex-shrink: 0;
`;
const ProjectNameText = styled(Text)`
  font-size: 1rem;
  flex-shrink: 0;
`;

const ProjectSearch = () => {
  const { value, onChange, data, loading } = useSearchProject();
  const selectProject = useSelectProject();
  return (
    <StyledAutoComplete
      dataSource={data}
      onSearch={onChange}
      onSelect={selectProject}
      value={value}
      notFoundContent={
        loading ? <Icon type="loading" /> : <Text>Not found.</Text>
      }
    >
      <Input placeholder="Project" prefix={<Icon type="search" />} />
    </StyledAutoComplete>
  );
};

const UnitSearch = () => {
  const [input, setInput] = useState("");
  const history = useHistory();
  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        history.push("/manage/label/" + input);
        setInput("");
      }}
    >
      <Input
        placeholder="Unit"
        prefix={<Icon type="search" />}
        value={input}
        onChange={e => setInput(e.target.value)}
      />
      <button style={{ display: "none" }} type="submit">
        submit
      </button>
    </form>
  );
};

const ManageProjectHeader = () => {
  const { stockId } = useParams();
  const selectedProject = useSelectedProjectState();
  return (
    <ManageHeaderContainer>
      <div style={{ display: "flex", alignItems: "center" }}>
        <ProjectSearch />
        <UnitSearch />
      </div>
      {!stockId && (
        <>
          <MobileHidden>
            <ProjectSummaryTable />
          </MobileHidden>
          <ProjectNameText ellipsis strong>
            <MobileHidden>{selectedProject}</MobileHidden>
          </ProjectNameText>
        </>
      )}
    </ManageHeaderContainer>
  );
};

const HeaderRoutes = () => {
  return (
    <Switch>
      <Route path="/manage/label/:stockId?" component={ManageProjectHeader} />
      <Route path="/manage/image/:stockId?" component={ManageProjectHeader} />
    </Switch>
  );
};

export default HeaderRoutes;
