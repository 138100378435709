import React, { useState } from "react";
import Item from "./Item";
import { useParams } from "react-router";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import styled from "@emotion/styled";
import { Button, Typography, Icon } from "antd";
import {
  useImages,
  useSelectImage,
  useUnlabeledUnit,
  useCustomUnitImages
} from "../hooks/unit";
import EditImageModal from "./EditImageModal";
import SubmitModal from "./SubmitModal";
import Img from "./Img";
import SubmitCustomUnitModal from "./SubmitCustomUnitModal";

const { Title, Text } = Typography;

const BucketImg = styled(Img)`
  height: 100%;
  width: auto;
  margin: 0 0.5rem 0 0;
`;

const SortableImagesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
`;
const SortableBucketContainer = styled.div`
  height: 120px;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  padding: 8px;
`;
const SortableBucketImageContainer = styled.div`
  position: relative;
  height: 100%;
  width: fit-content;
`;
const ImageActionContainer = styled.div`
  display: grid;
  grid-template-areas:
    "bucket bucket"
    "not-show show";
  grid-template-columns: 1fr 1fr;
  width: 100%;
`;

const SortableImageContainer = styled.div`
  position: relative;
  height: fit-content;
  margin: 0.25rem;
`;

const DragIcon = styled(Icon)`
  position: absolute;
  top: 8px;
  left: 8px;
  cursor: move;
  background: white;
  border-radius: 50%;
`;

const Scroll = styled.div`
  width: 100%;
  overflow-x: auto;
`;

const BucketContainer = styled.div`
  grid-area: bucket;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  overflow-x: auto;
`;
const ShowImageContainer = styled.div`
  grid-area: show;
  padding: 1rem 0;
  margin-left: 1rem;
`;
const NotShowImageContainer = styled.div`
  grid-area: not-show;
  padding: 1rem 0;
  border-right: 1px solid rgba(0, 0, 0, 0.08);
`;

const ButtonContainer = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 1rem;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
`;

const SubmitButton = styled(Button)`
  font-weight: bolder;
`;

const SortableImage = SortableElement(
  ({ src, onClick, selected, ...props }) => {
    return (
      <SortableImageContainer {...props}>
        <DragIcon type="drag" />
        <Img onClick={onClick} src={src} draggable={false} />
      </SortableImageContainer>
    );
  }
);
const BucketImage = SortableElement(({ src, onClick, selected, ...props }) => {
  return (
    <SortableBucketImageContainer {...props}>
      <DragIcon type="drag" />
      <BucketImg onClick={onClick} src={src} draggable={false} />
    </SortableBucketImageContainer>
  );
});

const SortableImages = SortableContainer(SortableImagesContainer);
const BucketImages = SortableContainer(SortableBucketContainer);

const shouldCancelStart = e => {
  return e.target.tagName && e.target.tagName.toLowerCase() === "img";
};

const Bucket = () => {
  const { selectImage } = useSelectImage();
  const { bucketImages, showImages, notShowImages, setImages } = useImages();
  return (
    <BucketContainer>
      <Title level={3}>Images</Title>
      <Scroll>
        <BucketImages
          axis="x"
          onSortEnd={({ oldIndex, newIndex }) => {
            const newBucketImages = arrayMove(bucketImages, oldIndex, newIndex);
            setImages(newBucketImages, showImages, notShowImages);
          }}
          shouldCancelStart={shouldCancelStart}
        >
          {bucketImages.length > 0 ? (
            bucketImages.map((im, index) => (
              <BucketImage
                draggable={false}
                src={im}
                key={index}
                index={index}
                onClick={() => {
                  selectImage(im, true);
                }}
              />
            ))
          ) : (
            <Text>No images.</Text>
          )}
        </BucketImages>
      </Scroll>
    </BucketContainer>
  );
};

const ShowImage = () => {
  const { selectImage } = useSelectImage();
  const { bucketImages, showImages, notShowImages, setImages } = useImages();
  return (
    <ShowImageContainer>
      <Title level={3}>Show Images</Title>
      <SortableImages
        axis="xy"
        onSortEnd={({ oldIndex, newIndex }) => {
          const newShowImages = arrayMove(showImages, oldIndex, newIndex);
          setImages(bucketImages, newShowImages, notShowImages);
        }}
        shouldCancelStart={shouldCancelStart}
      >
        {showImages.length > 0 ? (
          showImages.map((im, index) => (
            <SortableImage
              draggable={false}
              src={im}
              key={index}
              index={index}
              onClick={() => {
                selectImage(im, true);
              }}
            />
          ))
        ) : (
          <Text>No images.</Text>
        )}
      </SortableImages>
    </ShowImageContainer>
  );
};
const NotShowImage = () => {
  const { selectImage } = useSelectImage();
  const { bucketImages, showImages, notShowImages, setImages } = useImages();
  return (
    <NotShowImageContainer>
      <Title level={3}>Not Show Images</Title>
      <SortableImages
        axis="xy"
        onSortEnd={({ oldIndex, newIndex }) => {
          const newNotShowImages = arrayMove(notShowImages, oldIndex, newIndex);
          setImages(bucketImages, showImages, newNotShowImages);
        }}
        shouldCancelStart={shouldCancelStart}
      >
        {notShowImages.length > 0 ? (
          notShowImages.map((im, index) => (
            <SortableImage
              draggable={false}
              src={im}
              key={index}
              index={index}
              onClick={() => {
                selectImage(im, true);
              }}
            />
          ))
        ) : (
          <Text>No images.</Text>
        )}
      </SortableImages>
    </NotShowImageContainer>
  );
};

const Submit = () => {
  const [visible, setVisible] = useState(false);
  return (
    <ButtonContainer>
      <SubmitButton
        type="primary"
        shape="round"
        size="large"
        onClick={() => setVisible(true)}
      >
        SUBMIT <Icon type="check" />
      </SubmitButton>
      <SubmitCustomUnitModal
        visible={visible}
        onCancel={() => setVisible(false)}
      />
    </ButtonContainer>
  );
};

const UnitImageAction = () => {
  const { stockId } = useParams();
  const { images, loading } = useCustomUnitImages(stockId);
  if (loading) return null;
  return (
    <Item col>
      {images.length > 0 ? (
        <ImageActionContainer>
          <Bucket />
          <NotShowImage />
          <ShowImage />
        </ImageActionContainer>
      ) : (
        <Title level={3}>No Images.</Title>
      )}
      <Submit />
      <EditImageModal />
    </Item>
  );
};

export default UnitImageAction;
