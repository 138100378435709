import React, { useEffect } from "react";
import { Modal, Typography, notification, message } from "antd";
import styled from "@emotion/styled";
import { useLabelUnit, useImages, useConfirmLabel } from "../hooks/unit";
import DataInfo from "./DataInfo";
import Item from "./Item";
import Img from "./Img";
import { withRouter } from "react-router-dom";

const { Title, Text } = Typography;
const SummaryContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

const Data = styled.div`
  margin-top: 2rem;
`;
const DataGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
`;
const Left = styled(Text)`
  font-size: 1.1rem;
  font-weight: bolder;
  justify-self: flex-end;
`;
const Right = styled(Text)`
  font-size: 1rem;
`;
const Gallery = styled.div`
  grid-column: 1 / span 2;
  overflow-x: auto;
  display: flex;
  flex-flow: row nowrap;
`;
const ImgCard = styled.div`
  padding: 0.5rem 0.25rem;
`;

const SubmitModal = withRouter(({ visible, onCancel, history }) => {
  const { savedLabelUnit } = useLabelUnit();
  const { showImages, notShowImages } = useImages();
  const { confirm, loading, uploadedImg } = useConfirmLabel();
  useEffect(() => {
    if (uploadedImg) {
      notification.info({
        key: "submit notification",
        message: "Upload Status",
        description: `Upload finish ${uploadedImg} images`
      });
    }
  }, [uploadedImg]);
  const onOk = async () => {
    await confirm();
    history.replace("/manage/label");
  };
  const onCannotCancel = () => {
    message.error("Cannot close dialog.");
  };
  return (
    <Modal
      visible={visible}
      onCancel={loading ? onCannotCancel : onCancel}
      width="80vw"
      centered
      okText="Confirm"
      onOk={onOk}
      okButtonProps={{ loading }}
      cancelButtonProps={{ disabled: loading }}
      maskClosable={!loading}
    >
      <Title level={3}>Summary</Title>
      <SummaryContainer>
        <DataInfo hasSkip={false} />
        <Data>
          <Item col>
            <DataGrid>
              <Left>{savedLabelUnit.priceType}:</Left>
              <Right>{savedLabelUnit.price}</Right>
              <Left>Sale Type:</Left>
              <Right>{savedLabelUnit.sale_type}</Right>
              <Left>ภาษีธุรกิจเฉพาะ:</Left>
              <Right>
                {savedLabelUnit.has_transfer_tax.replace("ใช่", "มี")}
              </Right>
              <Title level={4}>Show Images</Title>
              <Gallery>
                {showImages.length > 0 ? (
                  showImages.map((src, index) => (
                    <ImgCard key={index}>
                      <Img src={src} cursor="default" />
                    </ImgCard>
                  ))
                ) : (
                  <Text>No images.</Text>
                )}
              </Gallery>
              <Title level={4}>Not Show Images</Title>
              <Gallery>
                {notShowImages.length > 0 ? (
                  notShowImages.map((src, index) => (
                    <ImgCard key={index}>
                      <Img src={src} cursor="default" />
                    </ImgCard>
                  ))
                ) : (
                  <Text>No images.</Text>
                )}
              </Gallery>
            </DataGrid>
          </Item>
        </Data>
      </SummaryContainer>
    </Modal>
  );
});

export default SubmitModal;
