import React, { Fragment } from "react";
import styled from "@emotion/styled";

const Container = styled.div`
  display: grid;
  grid-template-columns: 150px auto;
  grid-gap: 1rem;
  position: relative;
  over-flow-x: auto;
`;

const CamelToSentence = camelCase => {
  const sentence = camelCase.replace(/([A-Z])/g, " $1");
  return sentence.charAt(0).toUpperCase() + sentence.slice(1);
};

export const UnitDetailCard = ({ unit, mapper, ...props }) => {
  return (
    <Container {...props}>
      {mapper.map(key => {
        return (
          <Fragment key={key}>
            <div>{CamelToSentence(key)}</div>
            <div>{unit[key] || "-"}</div>
          </Fragment>
        );
      })}
    </Container>
  );
};
