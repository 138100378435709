import React from "react";
import { withRouter } from "react-router-dom";
import styled from "@emotion/styled";
import { Button, Icon, Typography, Spin, message } from "antd";
import Title from "antd/lib/typography/Title";
import Item from "./Item";
import { useUnlabeledUnit, useLabelUnit } from "../hooks/unit";
import SplittedNote from "./SplittedNote";

const { Text } = Typography;

const RightText = styled(Text)`
  font-size: 1rem;
`;
const LeftText = styled(Text)`
  font-size: 1rem;
  justify-self: flex-end;
`;
const DataContainer = styled.div`
  display: grid;
  grid-template-columns: 120px auto;
  grid-gap: 1rem;
  position: relative;
`;

const SkipButton = styled(Button)`
  font-weight: bolder;
  align-self: flex-end;
  margin-top: 1rem;
`;

const ButtonContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

export const DataInfoInner = ({ unit, hasSkip, onSkip, loading }) => {
  return (
    <Item>
      <DataContainer>
        <LeftText>
          <Title level={3}>Propfit ID :</Title>
        </LeftText>
        <RightText>
          <Title level={3}>{unit.stockId}</Title>
        </RightText>
        <LeftText strong>Plus Price :</LeftText>
        <RightText>
          {unit.plusPrice && unit.plusPrice.toLocaleString("en")}
        </RightText>
        <LeftText strong>Remark :</LeftText>
        <RightText>
          <SplittedNote note={unit.note} />
        </RightText>
      </DataContainer>
      {hasSkip && (
        <ButtonContainer>
          <SkipButton
            size="large"
            shape="round"
            type="primary"
            disabled={loading || !unit}
            onClick={onSkip}
          >
            SKIP
            <Icon type="vertical-left" />
          </SkipButton>
        </ButtonContainer>
      )}
    </Item>
  );
};

const DataInfo = withRouter(({ history, hasSkip = true }) => {
  const { loading, unlabeled_unit, error } = useUnlabeledUnit();
  const { labelUnit, saveLabelUnit } = useLabelUnit();
  const onSkip = async () => {
    if (unlabeled_unit) {
      const hide = message.loading("Saving...");
      await labelUnit(unlabeled_unit.id, { skip_label_price: true });
      saveLabelUnit(null);
      history.replace("/manage/label");
      hide();
      message.success(`Skip ${unlabeled_unit.stockId}!`);
    }
  };
  if (error) {
    message.error(error.message);
    return (
      <div>
        <Title level={3}>Something went wrong.</Title>
        <Text>
          Please contact product team. or{" "}
          <Button type="link" onClick={() => window.location.reload()}>
            try again!
          </Button>
        </Text>
      </div>
    );
  }
  if (loading) return <Spin />;
  if (!unlabeled_unit)
    return (
      <Title level={3}>
        This project is finished! Please search next project.
      </Title>
    );
  return (
    <DataInfoInner
      unit={unlabeled_unit}
      loading={loading}
      onSkip={onSkip}
      hasSkip={hasSkip}
    />
  );
});

export default DataInfo;
