import React from "react";
import { useSelectedProjectState } from "../hooks/project";
import { DesktopHidden } from "./Hidden";
import styled from "@emotion/styled";
import { Typography } from "antd";
import ProjectSummaryTable from "./ProjectSummaryTable";

const { Text } = Typography;

const ProjectNameText = styled(Text)`
  font-size: 1rem;
  flex-shrink: 0;
`;
const ProjectInfoMobileContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding-bottom: 2rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
`;
const ProjectInfoMobile = () => {
  const selectedProject = useSelectedProjectState();
  return (
    <DesktopHidden>
      <ProjectInfoMobileContainer>
        <ProjectNameText ellipsis strong>
          {selectedProject}
        </ProjectNameText>
        <ProjectSummaryTable />
      </ProjectInfoMobileContainer>
    </DesktopHidden>
  );
};

export default ProjectInfoMobile;
