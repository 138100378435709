export const COLOR = {
  PRIMARY: "#3F7DF4"
};

export const API_ENDPOINT =
  process.env.NODE_ENV === "production"
    ? "https://api.propfit.asia/label-tool"
    : "http://localhost:4000/label-tool";

export const PRICE_FIELD = {
  OWNER_NET: "ownerNet",
  PRICE_WITH_COM: "priceWithComm",
  PRICE_WITHOUT_COM: "priceWithoutCom",
  HALF: "half",
  PRICE_WITH_EVERYTHINGS: "priceWithEverythings"
};

export const PRICE_LABEL = {
  [PRICE_FIELD.OWNER_NET]: "ราคา Owner Net",
  [PRICE_FIELD.PRICE_WITH_COM]: "ราคารวมคอม",
  [PRICE_FIELD.PRICE_WITHOUT_COM]: "ราคารวมค่าใช้จ่าย ไม่รวมคอม",
  [PRICE_FIELD.HALF]: "ราคารวมคอม โอนคนละครึ่ง",
  [PRICE_FIELD.PRICE_WITH_EVERYTHINGS]: "ราคารวมทุกอย่าง"
};

export const TAX_OPTION = {
  HAVE: "มี",
  DONT_HAVE: "ไม่มี",
  DONT_KNOW: "ไม่รู้"
};

export const TYPE_OPTION = {
  DOWN: "ขายดาวน์",
  RTM_BEFORE: "RTM ก่อนโอน",
  RTM_AFTER: "RTM หลังโอน"
};

export const RENT_OPTION = {
  HAVE: "มีผู้เช่า",
  DONT_HAVE: "ไม่มีผู้เช่า"
};

export const HAS_TRANSFER_TAX = {
  HAVE: "ใช่",
  DONT_HAVE: "ไม่ใช่",
  DONT_KNOW: "ไม่รู้"
};

export const SALE_TYPE = {
  DOWN: "ดาวน์",
  RTM_AFTER: "RTM หลังโอน",
  RTM_BEFORE: "RTM ก่อนโอน"
};
