import React, { useState, useMemo } from "react";
import {
  Layout,
  Spin,
  Collapse,
  Typography,
  Form,
  message,
  Popover,
  Button
} from "antd";
import styled from "@emotion/styled";
import { DataInfoInner } from "./DataInfo";
import _ from "lodash";
import { useNeedUpdateUnits, useUpdateUnit } from "../hooks/unit";
import Item from "./Item";
import ActionForm from "./ActionForm";
import {
  SALE_TYPE,
  RENT_OPTION,
  HAS_TRANSFER_TAX,
  PRICE_LABEL,
  PRICE_FIELD
} from "../const";

const { Content } = Layout;
const { Panel } = Collapse;
const { Text, Title } = Typography;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  max-width: 1200px;
  margin: auto;
  grid-gap: 1rem;
`;
const UpdateDataContainer = styled(Content)`
  padding: 32px 16px;
  overflow: auto;
`;
const PanelHeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr auto;
  grid-gap: 1rem;
`;
const UpdateActionContainer = styled.div`
  padding: 1rem;
`;
const BottomItem = styled(Item)`
  margin-top: 2rem;
`;
const InfoTable = styled.div`
  display: grid;
  grid-template-areas:
    ". price remark"
    "old old-price old-remark"
    "new new-price new-remark";
  grid-gap: 0.5rem;
`;
const Area = styled.div`
  grid-area: ${props => props.area};
`;
const InfoButton = styled(Button)`
  margin-right: 0.5rem;
`;

const PanelHeader = ({ unit }) => {
  return (
    <PanelHeaderContainer>
      <Text strong>{unit.stockId}</Text>
      <Text>{_.get(unit, "shortProject.name")}</Text>
      <Popover
        content={
          <InfoTable>
            <Area area="price">
              <Text strong>Price</Text>
            </Area>
            <Area area="remark">
              <Text strong>Remark</Text>
            </Area>
            <Area area="old">
              <Text strong>Old</Text>
            </Area>
            <Area area="new">
              <Text strong>New</Text>
            </Area>
            <Area area="old-price">
              {unit.plusPriceWhenLabel &&
                unit.plusPriceWhenLabel.toLocaleString("en")}
            </Area>
            <Area area="old-remark">{unit.noteWhenLabel}</Area>
            <Area area="new-price">
              {unit.plusPrice && unit.plusPrice.toLocaleString("en")}
            </Area>
            <Area area="new-remark">{unit.note}</Area>
          </InfoTable>
        }
        trigger="hover"
        placement="bottomRight"
      >
        <InfoButton type="ghost" shape="circle" icon="info" size="small" />
      </Popover>
    </PanelHeaderContainer>
  );
};

const UpdateAction = Form.create("update_action")(
  ({ unit, form, disabled }) => {
    const { refetch } = useNeedUpdateUnits();
    const { updateUnit, loading } = useUpdateUnit();
    const onSkip = async () => {
      const hide = message.loading("Saving..", 0);
      try {
        await updateUnit(unit.id, {
          skip_label_price: true
        });
        hide();
        message.success("Update Success");
        await refetch();
      } catch (error) {
        message.error(error.message);
        hide();
      }
    };
    const onSubmit = e => {
      e.preventDefault();
      form.validateFields(async (error, values) => {
        if (!error) {
          const price = Object.values(PRICE_FIELD).reduce(
            (prev, curr) => prev || values[curr],
            0
          );
          if (!price) {
            message.error("Please input price data.");
          } else {
            const hide = message.loading("Saving..", 0);
            try {
              await updateUnit(unit.id, {
                sale_type: SALE_TYPE[values.type],
                rent_status: RENT_OPTION[values.rent],
                has_transfer_tax: HAS_TRANSFER_TAX[values.tax],
                priceType: PRICE_LABEL[values.selectedPriceOption],
                price: Number(price)
              });
              hide();
              message.success("Update Success");
              await refetch();
            } catch (error) {
              message.error(error.message);
              hide();
            }
          }
        }
      });
    };
    return (
      <UpdateActionContainer>
        <DataInfoInner
          unit={unit}
          onSkip={onSkip}
          loading={loading || disabled}
          hasSkip
        />
        <BottomItem>
          <ActionForm
            form={form}
            defaultPrice={unit.plusPrice}
            disabled={loading || disabled}
            onSubmit={onSubmit}
            submitText="SUBMIT"
          />
        </BottomItem>
      </UpdateActionContainer>
    );
  }
);

const UpdateData = () => {
  const { loading, error, data } = useNeedUpdateUnits();
  const [activeKey, setActiveKey] = useState([]);
  const sortedData = useMemo(() => {
    return _.get(data, "need_update_units", []).sort((a, b) =>
      a.stockId > b.stockId ? 1 : -1
    );
  }, [data]);
  console.log(data);
  if (loading) {
    return (
      <UpdateDataContainer>
        <Spin />
      </UpdateDataContainer>
    );
  }
  if (error) {
    return <UpdateDataContainer>{error.message}</UpdateDataContainer>;
  }
  return (
    <UpdateDataContainer>
      <Container>
        {sortedData.length > 0 ? (
          <Collapse
            bordered={false}
            activeKey={activeKey}
            onChange={setActiveKey}
          >
            {sortedData.map(unit => (
              <Panel header={<PanelHeader unit={unit} />} key={unit.id}>
                <UpdateAction unit={unit} disabled={loading} />
              </Panel>
            ))}
          </Collapse>
        ) : (
          <Title level={3}>Finished.</Title>
        )}
      </Container>
    </UpdateDataContainer>
  );
};

export default UpdateData;
