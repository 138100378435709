import React from "react";
import styled from "@emotion/styled";

const Splitted = styled.span`
  display: inline-block;
`;

const SplittedNote = ({ note }) => {
  if (!note) return null;
  const splitted = note.trim().split(/\s+/);
  return (
    <span>
      {splitted.map((txt, index) => (
        <Splitted key={index}>{txt}&nbsp;</Splitted>
      ))}
    </span>
  );
};

export default SplittedNote;
