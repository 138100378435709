const initialState = {
  user: null,
  selectedProject: localStorage.getItem("selectedProject") || "",
  searchProject: "",
  selectedImage: "",
  editImageModal: false,
  savedLabelUnit: {},
  labelLoading: false,
  bucketImages: [],
  showImages: [],
  notShowImages: [],
  selectedCustomUnit: ""
};

const TYPE = {
  LOGIN: "login",
  LOGOUT: "logout",
  SELECT_PROJECT: "selectProject",
  SELECT_UNIT: "selectUnit",
  SEARCH_PROJECT: "searchProject",
  SAVE_LABEL_UNIT: "saveLabelUnit",
  SELECT_IMAGE: "selectImage",
  LABEL_LOADING: "labelLoading",
  IMAGES: "images",
  SELECT_CUSTOM_UNIT: "selectCustomUnit"
};

export const loginAction = user => {
  return {
    type: TYPE.LOGIN,
    payload: user
  };
};

export const logoutAction = () => {
  return {
    type: TYPE.LOGOUT,
    payload: null
  };
};

export const selectProjectAction = project => {
  localStorage.setItem("selectedProject", project);
  return {
    type: TYPE.SELECT_PROJECT,
    payload: project
  };
};

export const selectUnitAction = unit => {
  return {
    type: TYPE.SELECT_UNIT,
    payload: unit
  };
};

export const searchProjectAction = project => {
  return {
    type: TYPE.SEARCH_PROJECT,
    payload: project
  };
};

export const saveLabelUnitAction = data => {
  return {
    type: TYPE.SAVE_LABEL_UNIT,
    payload: data
  };
};

export const selectImageAction = (image, open) => {
  return {
    type: TYPE.SELECT_IMAGE,
    payload: {
      selectedImage: image,
      editImageModal: open
    }
  };
};

export const setLabelLoadingAction = loading => {
  return {
    type: TYPE.LABEL_LOADING,
    payload: loading
  };
};

export const setImagesAction = (bucketImages, showImages, notShowImages) => {
  return {
    type: TYPE.IMAGES,
    payload: {
      bucketImages,
      showImages,
      notShowImages
    }
  };
};

export const selectCustomUnitAction = unitId => {
  return {
    type: TYPE.SELECT_CUSTOM_UNIT,
    payload: {
      selectedCustomUnit: unitId
    }
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPE.LOGIN:
      return { ...state, user: action.payload };
    case TYPE.LOGOUT:
      return { ...state, user: action.payload };
    case TYPE.SELECT_PROJECT:
      return { ...state, selectedProject: action.payload };
    case TYPE.SELECT_UNIT:
      return { ...state, selectedUnit: action.payload };
    case TYPE.SEARCH_PROJECT:
      return { ...state, searchProject: action.payload };
    case TYPE.SAVE_LABEL_UNIT:
      return { ...state, savedLabelUnit: action.payload };
    case TYPE.SELECT_IMAGE:
      return { ...state, ...action.payload };
    case TYPE.LABEL_LOADING:
      return { ...state, labelLoading: action.payload };
    case TYPE.IMAGES:
      return { ...state, ...action.payload };
    case TYPE.SELECT_CUSTOM_UNIT:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default reducer;
