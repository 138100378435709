import React from "react";
import styled from "@emotion/styled";
import { Layout } from "antd";
import ProjectInfoMobile from "./ProjectInfoMobile";
import DataInfo from "./DataInfo";
import ImageAction from "./ImageAction";
import { Redirect } from "react-router-dom";
import _ from "lodash";
import { useLabelUnit } from "../hooks/unit";

const { Content } = Layout;

const ImageDataContainer = styled(Content)`
  padding: 32px 16px;
  overflow: auto;
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  max-width: 1200px;
  margin: auto;
  grid-gap: 1rem;
`;

const ImageData = () => {
  const { savedLabelUnit } = useLabelUnit();
  if (!_.get(savedLabelUnit, "id")) {
    return <Redirect to="/manage/label" />;
  }
  return (
    <ImageDataContainer>
      <Container>
        <ProjectInfoMobile />
        <DataInfo />
        <ImageAction />
      </Container>
    </ImageDataContainer>
  );
};

export default ImageData;
