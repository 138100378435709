import React, { useEffect } from "react";
import { Layout } from "antd";
import Sidebar from "./Sidebar";
import { Switch, Route, Redirect } from "react-router-dom";
import LabelData from "./LabelData";
import { useUser } from "../hooks/user";
import ImageData from "./ImageData";
import styled from "@emotion/styled";
import {
  useSelectedProjectState,
  useSelectProject,
  useSearchProject
} from "../hooks/project";
import UpdateData from "./UpdateData";
import HeaderRoutes from "./HeaderRoutes";
import ProjectSummary from "./ProjectSummary";
import Login from "./Login";
import { SearchPage } from "features/search/SearchPage";
import LabelUnitData from "./LabelUnitData";
import UnitImageData from "./UnitImageData";

const RouteContainer = styled(Layout)`
  max-height: 100vh;
`;

const ManageRoutes = () => {
  return (
    <Switch>
      <Route path="/manage/label" exact component={LabelData} />
      <Route path="/manage/image" exact component={ImageData} />
      <Route path="/manage/label/:stockId" exact component={LabelUnitData} />
      <Route path="/manage/image/:stockId" exact component={UnitImageData} />
      <Route path="/manage/update" exact component={UpdateData} />
    </Switch>
  );
};

const SummaryRoutes = () => {
  return (
    <Switch>
      <Route path="/summary/project" component={ProjectSummary} />
    </Switch>
  );
};

const AuthenticatedRoutes = () => {
  const { user, loading } = useUser();
  const selectedProject = useSelectedProjectState();
  const selectProject = useSelectProject();
  const { data } = useSearchProject();
  if (!selectedProject && data.length > 0) {
    selectProject(data[0]);
  }
  if (!user && loading) return "Checking User Credential";
  if (!user) return <Redirect to="/login" />;
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sidebar />
      <RouteContainer>
        <HeaderRoutes />
        {selectedProject && (
          <Switch>
            <Route path="/manage" component={ManageRoutes} />
            <Route path="/summary" component={SummaryRoutes} />
            <Route path="/search" component={SearchPage} />
            <Redirect to="/manage/label" />
          </Switch>
        )}
      </RouteContainer>
    </Layout>
  );
};

const Routes = () => {
  return (
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/" component={AuthenticatedRoutes} />
      <Redirect to="/login" />
    </Switch>
  );
};

export default Routes;
