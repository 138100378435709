import React from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import "./App.css";
import "normalize.css";
import "antd/dist/antd.css";
import "react-image-crop/dist/ReactCrop.css";
import Login from "./components/Login";
import Routes from "./components/Routes";
import { Provider } from "react-redux";
import store from "./redux/store";
import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
  from
} from "apollo-boost";
import { API_ENDPOINT } from "./const";
import { ApolloProvider } from "@apollo/react-hooks";

const history = createBrowserHistory();

const cache = new InMemoryCache();
const authLink = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem("token");
  if (token) {
    operation.setContext(() => ({
      headers: {
        authorization: token ? `Bearer ${token}` : null
      }
    }));
  }
  return forward(operation);
});
const httpLink = new HttpLink({ uri: API_ENDPOINT });
const apolloClient = new ApolloClient({
  link: from([authLink, httpLink]),
  cache
});

const App = () => {
  return (
    <ApolloProvider client={apolloClient}>
      <Provider store={store}>
        <Router history={history}>
          <Routes />
        </Router>
      </Provider>
    </ApolloProvider>
  );
};

export default App;
