import React, { useEffect } from "react";
import { Layout, message, Form } from "antd";
import styled from "@emotion/styled";
import { useParams, useHistory } from "react-router";
import { Redirect } from "react-router-dom";
import { DataInfoInner } from "./DataInfo";
import DataAction from "./DataAction";
import ProjectInfoMobile from "./ProjectInfoMobile";
import { useLabelUnit, useCustomUnlabeledUnit } from "../hooks/unit";
import _ from "lodash";
import Item from "./Item";
import ActionForm from "./ActionForm";
import {
  PRICE_FIELD,
  SALE_TYPE,
  RENT_OPTION,
  PRICE_LABEL,
  HAS_TRANSFER_TAX
} from "../const";

const { Content } = Layout;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  max-width: 1200px;
  margin: auto;
  grid-gap: 1rem;
`;

const LabelDataContainer = styled(Content)`
  padding: 32px 16px;
  overflow: auto;
`;

const LabelUnitDataAction = Form.create({ name: "action" })(
  ({ form, unit, loading }) => {
    const { saveLabelUnit } = useLabelUnit();
    const history = useHistory();
    const onSubmit = e => {
      e.preventDefault();
      if (!loading) {
        form.validateFields((error, values) => {
          if (!error) {
            const price = Object.values(PRICE_FIELD).reduce(
              (prev, curr) => prev || values[curr],
              0
            );
            if (!price) {
              message.error("Please input price data.");
            } else {
              saveLabelUnit({
                id: unit.id,
                sale_type: SALE_TYPE[values.type],
                rent_status: RENT_OPTION[values.rent],
                has_transfer_tax: HAS_TRANSFER_TAX[values.tax],
                priceType: PRICE_LABEL[values.selectedPriceOption],
                price: Number(price),
                unit
              });
              history.replace("/manage/image/" + unit.stockId);
            }
          }
        });
      }
    };
    return (
      <Item>
        <ActionForm
          form={form}
          onSubmit={onSubmit}
          defaultPrice={_.get(unit, "plusPrice")}
          disabled={loading}
          submitText="NEXT"
        />
      </Item>
    );
  }
);

const LabelUnitData = () => {
  const { stockId } = useParams();
  const history = useHistory();
  const { labelUnit, saveLabelUnit } = useLabelUnit();
  const { data = {}, loading, error } = useCustomUnlabeledUnit(stockId);
  const { custom_unlabeled_unit: unit } = data;

  if (error) {
    return `error : ${error}`;
  }
  if (loading) return "Loading...";
  if (!unit) {
    return "Not found / Already Label";
  }
  const { id } = unit;
  const onSkip = async () => {
    const hide = message.loading("Saving...");
    await labelUnit(id, { skip_label_price: true });
    saveLabelUnit(null);
    history.replace("/manage/label");
    hide();
    message.success(`Skip ${stockId}!`);
  };
  console.log(data);
  return (
    <LabelDataContainer>
      <Container>
        <DataInfoInner
          unit={unit}
          hasSkip={true}
          onSkip={onSkip}
          loading={loading}
        />
        <LabelUnitDataAction unit={unit} loading={loading} />
      </Container>
    </LabelDataContainer>
  );
};

export default LabelUnitData;
