import styled from "@emotion/styled";

const Img = styled.img`
  width: 160px;
  height: auto;
  cursor: ${props => props.cursor || "pointer"};
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7);
`;

export default Img;
