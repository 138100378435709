import React from "react";
import { useSelectedProjectState, useProjectSummary } from "../hooks/project";
import styled from "@emotion/styled";
import { Typography } from "antd";

const { Text } = Typography;

const Container = styled.div`
  display: grid;
  font-size: 0.9rem;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 24px 24px;
  grid-column-gap: 1rem;
  align-items: center;
  justify-items: center;
  margin: 0 1rem;
`;

const ProjectSummaryTableInner = ({ selectedProject }) => {
  const { data, loading } = useProjectSummary(selectedProject);
  if (loading) return null;
  return (
    <Container>
      <Text strong>Cleaned</Text>
      <Text strong>Uncleaned</Text>
      <Text strong>Skipped</Text>
      <Text>{data.cleaned}</Text>
      <Text>{data.uncleaned}</Text>
      <Text>{data.skipped}</Text>
    </Container>
  );
};

const ProjectSummaryTable = () => {
  const selectedProject = useSelectedProjectState();
  if (!selectedProject) return null;
  return <ProjectSummaryTableInner selectedProject={selectedProject} />;
};

export default ProjectSummaryTable;
