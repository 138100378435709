import React from "react";
import { Layout } from "antd";
import styled from "@emotion/styled";

const { Content } = Layout;

const ProjectSummaryContainer = styled(Content)`
  padding: 32px 16px;
`;
const ProjectSummaryFrame = styled.iframe`
  background: transparent;
  border: 1px solid #ccc;
  width: 100%;
  height: 100%;
`;

const ProjectSummary = () => {
  return (
    <ProjectSummaryContainer>
      <ProjectSummaryFrame
        className="airtable-embed"
        src="https://airtable.com/embed/shrVHnmyyVkFJSPhx?backgroundColor=green&viewControls=on"
        frameborder="0"
        onmousewheel=""
      />
    </ProjectSummaryContainer>
  );
};

export default ProjectSummary;
