import React from "react";
import styled from "@emotion/styled";
import { Layout, message } from "antd";
import ProjectInfoMobile from "./ProjectInfoMobile";
import DataInfo, { DataInfoInner } from "./DataInfo";
import { Redirect } from "react-router-dom";
import { useParams, useHistory } from "react-router";
import _ from "lodash";
import { useLabelUnit, useCustomUnlabeledUnit } from "../hooks/unit";
import UnitImageAction from "./UnitImageAction";

const { Content } = Layout;

const ImageDataContainer = styled(Content)`
  padding: 32px 16px;
  overflow: auto;
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  max-width: 1200px;
  margin: auto;
  grid-gap: 1rem;
`;

const UnitImageData = () => {
  const { stockId } = useParams();
  const history = useHistory();
  const { labelUnit, saveLabelUnit, savedLabelUnit } = useLabelUnit();
  const { data, loading, error } = useCustomUnlabeledUnit(stockId);
  if (!savedLabelUnit || !savedLabelUnit.id) {
    return <Redirect to="/manage/label" />;
  }
  if (error) {
    return <Redirect to="/manage/label" />;
  }
  if (loading) return "Loading...";
  if (!data) {
    return <Redirect to="/manage/label" />;
  }
  const unit = data.custom_unlabeled_unit;
  const { id } = unit;
  const onSkip = async () => {
    const hide = message.loading("Saving...");
    await labelUnit(id, { skip_label_price: true });
    saveLabelUnit(null);
    history.replace("/manage/label");
    hide();
    message.success(`Skip ${stockId}!`);
  };
  console.log(data);
  return (
    <ImageDataContainer>
      <Container>
        <DataInfoInner
          unit={unit}
          hasSkip={true}
          onSkip={onSkip}
          loading={loading}
        />
        <UnitImageAction />
      </Container>
    </ImageDataContainer>
  );
};

export default UnitImageData;
