import React, { Fragment, useCallback } from "react";
import Item from "./Item";
import styled from "@emotion/styled";
import { useHistory } from "react-router";
import _ from "lodash";
import { Form, Input, Typography, Radio, Button, Icon, message } from "antd";
import {
  useLabelUnit,
  useUnlabeledUnit,
  useLabelLoadingState
} from "../hooks/unit";
import {
  API_ENDPOINT,
  PRICE_FIELD,
  PRICE_LABEL,
  TAX_OPTION,
  TYPE_OPTION,
  RENT_OPTION,
  HAS_TRANSFER_TAX,
  SALE_TYPE
} from "../const";
import ActionForm from "./ActionForm";

const DataAction = Form.create({ name: "action" })(({ form }) => {
  const history = useHistory();
  const { unlabeled_unit } = useUnlabeledUnit();
  const labelLoading = useLabelLoadingState();
  const { labelUnit, saveLabelUnit } = useLabelUnit();
  const onSubmit = useCallback(
    e => {
      e.preventDefault();
      if (!labelLoading) {
        form.validateFields((error, values) => {
          if (!error) {
            const price = Object.values(PRICE_FIELD).reduce(
              (prev, curr) => prev || values[curr],
              0
            );
            if (!price) {
              message.error("Please input price data.");
            } else {
              saveLabelUnit({
                id: unlabeled_unit.id,
                sale_type: SALE_TYPE[values.type],
                rent_status: RENT_OPTION[values.rent],
                has_transfer_tax: HAS_TRANSFER_TAX[values.tax],
                priceType: PRICE_LABEL[values.selectedPriceOption],
                price: Number(price),
                unlabeled_unit
              });
              history.replace("/manage/image");
            }
          }
        });
      }
    },
    [form, labelUnit, saveLabelUnit, unlabeled_unit, history]
  );
  if (!unlabeled_unit) return null;
  return (
    <Item>
      <ActionForm
        form={form}
        onSubmit={onSubmit}
        defaultPrice={_.get(unlabeled_unit, "plusPrice")}
        disabled={labelLoading}
        submitText="NEXT"
      />
    </Item>
  );
});

export default DataAction;
