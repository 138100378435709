import styled from "@emotion/styled";

const Item = styled.div`
  width: 100%;
  padding: 2rem;
  border-radius: 1rem;
  background: white;
  box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  flex-flow: ${props => (props.col ? "column" : "row")} wrap;
  @media screen and (max-width: 600px) {
    padding: 1rem;
  }
`;

export default Item;
