import React from "react";
import styled from "@emotion/styled";
import { Form, Icon, Input, Button, message } from "antd";
import { COLOR } from "../const";
import { useUser, useLogin } from "../hooks/user";
import { Redirect } from "react-router-dom";

const Container = styled.div`
  width: 100%;
  height: 100vh;
  background: ${COLOR.PRIMARY};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Img = styled.img`
  padding: 0.5rem 0;
  margin-bottom: 1rem;
  width: 100%;
`;

const LoginBox = styled.div`
  padding: 2rem;
  max-width: 100%;
  width: 400px;
  display: flex;
  flex-flow: column nowrap;
  border-radius: 2rem;
  justify-content: center;
  background-color: white;
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.1);
`;

const Login = Form.create({ name: "login" })(({ form, history }) => {
  const { user, loading: userLoading } = useUser();
  const { login, loading } = useLogin();
  const { getFieldDecorator } = form;
  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const email = form.getFieldValue("email");
      const password = form.getFieldValue("password");
      await login(email, password);
    } catch (error) {
      message.error(error.message);
    }
  };
  if (userLoading) return null;
  if (user) return <Redirect to="/manage" />;
  return (
    <Container>
      <LoginBox>
        <Img src="/logo/logo_propfit.svg" />
        <Form onSubmit={handleSubmit}>
          <Form.Item>
            {getFieldDecorator("email", {
              rules: [{ required: true, message: "Please input your email!" }]
            })(
              <Input
                prefix={
                  <Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                disabled={loading}
                placeholder="email"
              />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("password", {
              rules: [
                { required: true, message: "Please input your Password!" }
              ]
            })(
              <Input
                prefix={
                  <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                type="password"
                placeholder="Password"
                disabled={loading}
              />
            )}
          </Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{ width: "100%" }}
            loading={loading}
          >
            Login
          </Button>
        </Form>
      </LoginBox>
    </Container>
  );
});

export default Login;
