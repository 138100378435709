import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef
} from "react";
import {
  useEditImageModalState,
  useSelectImage,
  useSelectedImageState,
  useImages
} from "../hooks/unit";
import { Modal, Button, Typography } from "antd";
import styled from "@emotion/styled";
import ReactCrop from "react-image-crop";
import { COLOR } from "../const";

const { Title } = Typography;

const Crop = styled(ReactCrop)`
  width: 100%;
  height: auto;
  .ReactCrop__drag-handle {
    background-color: ${COLOR.PRIMARY};
    width: 12px;
    height: 12px;
  }
`;
const ModalFooter = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
`;
const ImageEditorContainer = styled.div`
  width: fit-content;
  margin: auto;
  text-align: center;
`;

/**
 * @param {HTMLImageElement} image - Image File Object
 * @param {Object} crop - crop Object
 * @param {String} fileName - Name of the returned file in Promise
 */
const getCroppedImg = (image, crop, fileName) => {
  const canvas = document.createElement("canvas");
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx = canvas.getContext("2d");

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height
  );

  // As Base64 string
  const base64Image = canvas.toDataURL("image/jpeg");
  return base64Image;
};

const ImageEditor = forwardRef(({ image }, ref) => {
  const [crop, setCrop] = useState({
    unit: "%", // default, can be 'px' or '%'
    x: 0,
    y: 0,
    width: 100,
    height: 100
  });
  const imageRef = useRef();
  useImperativeHandle(
    ref,
    () => ({
      getCroppedImg: () =>
        getCroppedImg(imageRef.current, crop, new Date().toISOString())
    }),
    [crop, imageRef]
  );
  return (
    <ImageEditorContainer>
      <Crop
        crossorigin="anonymous"
        onImageLoaded={image => (imageRef.current = image)}
        src={image}
        crop={crop}
        onChange={newCrop => setCrop(newCrop)}
      />
    </ImageEditorContainer>
  );
});
const EditImageModal = () => {
  const editImageModal = useEditImageModalState();
  const { selectImage } = useSelectImage();
  const selectedImage = useSelectedImageState();
  const onCancel = () => selectImage("", false);
  const cropRef = useRef();
  const { bucketImages, showImages, notShowImages, setImages } = useImages();
  const removeImage = image => {
    [bucketImages, showImages, notShowImages].forEach(list => {
      const index = list.findIndex(e => e === image);
      if (index > -1) {
        list.splice(index, 1);
      }
    });
  };
  const submit = bucket => {
    const cropped = cropRef.current.getCroppedImg();
    removeImage(selectedImage);
    if (bucket === "show") {
      setImages(
        [...bucketImages],
        [...showImages, cropped],
        [...notShowImages]
      );
    } else {
      setImages(
        [...bucketImages],
        [...showImages],
        [...notShowImages, cropped]
      );
    }
    onCancel();
  };
  return (
    <Modal
      centered
      destroyOnClose
      visible={editImageModal}
      onCancel={onCancel}
      width={"80vw"}
      footer={
        <ModalFooter>
          <Button type="danger" onClick={onCancel}>
            Cancel
          </Button>
          <div>
            <Button type="primary" onClick={() => submit("not-show")}>
              SUBMIT TO NOTSHOW
            </Button>
            <Button type="primary" onClick={() => submit("show")}>
              SUBMIT TO SHOW
            </Button>
          </div>
        </ModalFooter>
      }
    >
      <Title level={3}>Crop Image</Title>
      <ImageEditor image={selectedImage} ref={cropRef} />
    </Modal>
  );
};

export default EditImageModal;
