import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { useQuery } from "@apollo/react-hooks";
import { searchProjectAction, selectProjectAction } from "../redux/reducer";
import gql from "graphql-tag";
import { useCallback, useMemo } from "react";

const SEARCH_PROJECT = gql`
  query projects_index($limit: Int, $query_str: String) {
    projects_index(limit: $limit, query_str: $query_str) {
      name
    }
  }
`;

const GET_PROJECT_INFO = gql`
  query project_info($project_name: String!) {
    short_project_info(project_name: $project_name) {
      unit_count
      done_label_unit_count
      skip_label_unit_count
    }
  }
`;

export const useSelectedProjectState = () => {
  const selectedProject = useSelector(state =>
    _.get(state, ["selectedProject"])
  );
  return selectedProject;
};

export const useSelectProject = () => {
  const dispatch = useDispatch();
  const selectProject = useCallback(e => dispatch(selectProjectAction(e)), [
    dispatch
  ]);
  return selectProject;
};

export const useProjectSummary = () => {
  const selectedProject = useSelectedProjectState();
  const { data, ...rest } = useQuery(GET_PROJECT_INFO, {
    variables: { project_name: selectedProject }
  });
  const cleaned = useMemo(
    () => _.get(data, "short_project_info.done_label_unit_count"),
    [data]
  );
  const skipped = useMemo(
    () => _.get(data, "short_project_info.skip_label_unit_count"),
    [data]
  );
  const uncleaned = useMemo(
    () => _.get(data, "short_project_info.unit_count") - (cleaned + skipped),
    [data, cleaned, skipped]
  );
  return {
    data: {
      cleaned,
      uncleaned,
      skipped
    },
    ...rest
  };
};

export const useSearchProject = () => {
  const dispatch = useDispatch();
  const value = useSelector(state => _.get(state, ["searchProject"]));
  const onChange = useCallback(e => dispatch(searchProjectAction(e)), [
    dispatch
  ]);
  const { data, loading } = useQuery(SEARCH_PROJECT, {
    variables: { query_str: value }
  });
  return {
    value,
    onChange,
    data:
      !loading && data && data.projects_index
        ? data.projects_index.map(e => e.name)
        : [],
    loading
  };
};
