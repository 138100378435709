import styled from "@emotion/styled";

export const MobileHidden = styled.div`
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const DesktopHidden = styled.div`
  @media screen and (min-width: 769px) {
    display: none;
  }
`;
